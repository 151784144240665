import bubbleUtils from '../../../bubble-utils';

export const SEARCH_MULTI_INDEX = 'SEARCH_MULTI_INDEX';
export const SEARCH_MULTI_INDEX_SUCCESS = 'SEARCH_MULTI_INDEX_SUCCESS';
export const SEARCH_MULTI_INDEX_FAIL = 'SEARCH_MULTI_INDEX_FAIL';

export const SEARCH_AUTHORS = 'SEARCH_AUTHORS';
export const SEARCH_AUTHORS_SUCCESS = 'SEARCH_AUTHORS_SUCCESS';
export const SEARCH_AUTHORS_FAIL = 'SEARCH_AUTHORS_FAIL';

export const SEARCH_STORES = 'SEARCH_STORES';
export const SEARCH_STORES_SUCCESS = 'SEARCH_STORES_SUCCESS';
export const SEARCH_STORES_FAIL = 'SEARCH_STORES_FAIL';

export const SEARCH_RELAYS = 'SEARCH_RELAYS';
export const SEARCH_RELAYS_SUCCESS = 'SEARCH_RELAYS_SUCCESS';
export const SEARCH_RELAYS_FAIL = 'SEARCH_RELAYS_FAIL';

export const SEARCH_SERIES = 'SEARCH_SERIES';
export const SEARCH_SERIES_SUCCESS = 'SEARCH_SERIES_SUCCESS';
export const SEARCH_SERIES_FAIL = 'SEARCH_SERIES_FAIL';

export const SEARCH_ALBUMS = 'SEARCH_ALBUMS';
export const SEARCH_ALBUMS_SUCCESS = 'SEARCH_ALBUMS_SUCCESS';
export const SEARCH_ALBUMS_FAIL = 'SEARCH_ALBUMS_FAIL';

export const SEARCH_ARTICLES = 'SEARCH_ARTICLES';
export const SEARCH_ARTICLES_SUCCESS = 'SEARCH_ARTICLES_SUCCESS';
export const SEARCH_ARTICLES_FAIL = 'SEARCH_ARTICLES_FAIL';

export const SEARCH_PUBLISHERS = 'SEARCH_PUBLISHERS';
export const SEARCH_PUBLISHERS_SUCCESS = 'SEARCH_PUBLISHERS_SUCCESS';
export const SEARCH_PUBLISHERS_FAIL = 'SEARCH_PUBLISHERS_FAIL';

export const SEARCH_COLLECTIONS = 'SEARCH_COLLECTIONS';
export const SEARCH_COLLECTIONS_SUCCESS = 'SEARCH_COLLECTIONS_SUCCESS';
export const SEARCH_COLLECTIONS_FAIL = 'SEARCH_COLLECTIONS_FAIL';

export const SEARCH_TYPES = 'SEARCH_TYPES';
export const SEARCH_TYPES_SUCCESS = 'SEARCH_TYPES_SUCCESS';
export const SEARCH_TYPES_FAIL = 'SEARCH_TYPES_FAIL';

export const SEARCH_TAGS = 'SEARCH_TAGS';
export const SEARCH_TAGS_SUCCESS = 'SEARCH_TAGS_SUCCESS';
export const SEARCH_TAGS_FAIL = 'SEARCH_TAGS_FAIL';

export const SEARCH_PLACES = 'SEARCH_PLACES';
export const SEARCH_PLACES_SUCCESS = 'SEARCH_PLACES_SUCCESS';
export const SEARCH_PLACES_FAIL = 'SEARCH_PLACES_FAIL';

export const RESET_SEARCH = 'RESET_SEARCH';
export const RESET_RELAYS = 'RESET_RELAYS';
export const SET_IS_ACTIVE = 'SET_IS_ACTIVE';

const initialState = {
  hitsSeries: { type: 'serie', data: [] },
  hitsAuthors: { type: 'author', data: [] },
  hitsStores: { type: 'store', data: [] },
  hitsArticles: { type: 'article', data: [] },
  hitsAlbums: { type: 'album', data: [] },
  hitsPublishers: { type: 'publisher', data: [] },
  hitsCollections: { type: 'collection', data: [] },
  hitsTypes: { type: 'type', data: [] },
  hitsTags: { type: 'tag', data: [] },
  hitsSeriesPersistent: { type: 'serie', data: [] },
  hitsAuthorsPersistent: { type: 'author', data: [] },
  hitsStoresPersistent: { type: 'store', data: [] },
  hitsArticlesPersistent: { type: 'article', data: [] },
  hitsAlbumsPersistent: { type: 'album', data: [] },
  hitsPublishersPersistent: { type: 'publisher', data: [] },
  hitsCollectionsPersistent: { type: 'collection', data: [] },
  hitsTypesPersistent: { type: 'type', data: [] },
  hitsTagsPersistent: { type: 'tag', data: [] },
  sorting: [
    'hitsSeries',
    'hitsAlbums',
    'hitsAuthors',
    'hitsArticles',
    'hitsStores',
    'hitsPublishers',
  ],
  hitsRelays: [],

  text: '',
  searching: false,
  isActive: false,
  relays: [],
  places: [],

  paginatedArticlesHits: {},
  loading: false,
  errors: [],
};

//TODO: use initialState
const resetHits = () => {
  return {
    hitsSeries: Object.assign({}, initialState.hitsSeries, { totalHits: 0, data: [] }),
    hitsAuthors: Object.assign({}, initialState.hitsAuthors, { totalHits: 0, data: [] }),
    hitsStores: Object.assign({}, initialState.hitsStores, { totalHits: 0, data: [] }),
    hitsArticles: Object.assign({}, initialState.hitsArticles, { totalHits: 0, data: [] }),
    hitsAlbums: Object.assign({}, initialState.hitsAlbums, { totalHits: 0, data: [] }),
    hitsPublishers: Object.assign({}, initialState.hitsPublishers, { totalHits: 0, data: [] }),
    hitsCollections: Object.assign({}, initialState.hitsCollections, { totalHits: 0, data: [] }),
    hitsTypes: Object.assign({}, initialState.hitsTypes, { totalHits: 0, data: [] }),
    hitsTags: Object.assign({}, initialState.hitsTags, { totalHits: 0, data: [] }),
    hitsSeriesPersistent: Object.assign({}, initialState.hitsSeriesPersistent, {
      totalHits: 0,
      data: [],
    }),
    hitsAuthorsPersistent: Object.assign({}, initialState.hitsAuthorsPersistent, {
      totalHits: 0,
      data: [],
    }),
    hitsStoresPersistent: Object.assign({}, initialState.hitsStoresPersistent, {
      totalHits: 0,
      data: [],
    }),
    hitsArticlesPersistent: Object.assign({}, initialState.hitsArticlesPersistent, {
      totalHits: 0,
      data: [],
    }),
    hitsAlbumsPersistent: Object.assign({}, initialState.hitsAlbumsPersistent, {
      totalHits: 0,
      data: [],
    }),
    hitsPublishersPersistent: Object.assign({}, initialState.hitsPublishersPersistent, {
      totalHits: 0,
      data: [],
    }),
    hitsCollectionsPersistent: Object.assign({}, initialState.hitsCollectionsPersistent, {
      totalHits: 0,
      data: [],
    }),
    hitsTypesPersistent: Object.assign({}, initialState.hitsTypesPersistent, {
      totalHits: 0,
      data: [],
    }),
    hitsTagsPersistent: Object.assign({}, initialState.hitsTagsPersistent, {
      totalHits: 0,
      data: [],
    }),
  };
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SEARCH_MULTI_INDEX: {
      return {
        ...state,
        text: action.payload.text,
        loading: true,
      };
    }
    case SEARCH_MULTI_INDEX_SUCCESS: {
      let hits = action.payload.hits.results;
      const hitMap = {
        hitsAlbums: Object.assign({}, initialState.hitsAlbums, { totalHits: 0, data: [] }),
        hitsSeries: Object.assign({}, initialState.hitsSeries, { totalHits: 0, data: [] }),
        hitsAuthors: Object.assign({}, initialState.hitsAuthors, { totalHits: 0, data: [] }),
        hitsPublishers: Object.assign({}, initialState.hitsPublishers, { totalHits: 0, data: [] }),
        hitsStores: Object.assign({}, initialState.hitsStores, { totalHits: 0, data: [] }),
        hitsArticles: Object.assign({}, initialState.hitsArticles, { totalHits: 0, data: [] }),
        hitsAlbumsPersistent: { ...state.hitsAlbumsPersistent },
        hitsSeriesPersistent: { ...state.hitsSeriesPersistent },
        hitsAuthorsPersistent: { ...state.hitsAuthorsPersistent },
        hitsPublishersPersistent: { ...state.hitsPublishersPersistent },
        hitsStoresPersistent: { ...state.hitsStoresPersistent },
        hitsArticlesPersistent: { ...state.hitsArticlesPersistent },
      };
      hits.forEach((index) => {
        const baseIndex = `hits${index.index}`;
        hitMap[baseIndex + (action.payload.persistent ? 'Persistent' : '')].data = index.hits;
        hitMap[baseIndex + (action.payload.persistent ? 'Persistent' : '')].totalHits =
          index.nbHits;
      });
      return {
        ...state,
        ...hitMap,
        loading: false,
        errors: [],
      };
    }
    case SEARCH_MULTI_INDEX_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_AUTHORS: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_AUTHORS_SUCCESS: {
      let newHitsAuthors = Object.assign({}, state.hitsAuthors);
      newHitsAuthors.data = action.payload.results?.results?.[0].hits || [];
      return {
        ...state,
        hits: resetHits(),
        ['hitsAuthors' + (action.payload.persistent ? 'Persistent' : '')]: newHitsAuthors,
        loading: false,
        errors: [],
      };
    }
    case SEARCH_AUTHORS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case SEARCH_SERIES: {
      return {
        ...state,
        text: action.payload.text,
        loading: true,
      };
    }
    case SEARCH_SERIES_SUCCESS: {
      let newHitsSeries = Object.assign({}, state.hitsSeries);
      newHitsSeries.data = action.payload.results?.results?.[0].hits || [];
      return {
        ...state,
        hits: resetHits(),
        ['hitsSeries' + (action.payload.persistent ? 'Persistent' : '')]: newHitsSeries,
        loading: false,
        errors: [],
      };
    }
    case SEARCH_SERIES_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case SEARCH_ALBUMS: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_ALBUMS_SUCCESS: {
      let newHitsAlbums = { ...state.hitsAlbums };
      newHitsAlbums.data = action.payload.results?.results?.[0].hits || [];
      return {
        ...state,
        hits: resetHits(),
        ['hitsAlbums' + (action.payload.persistent ? 'Persistent' : '')]: newHitsAlbums,
        loading: false,
        errors: [],
      };
    }
    case SEARCH_ALBUMS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case SEARCH_ARTICLES: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_ARTICLES_SUCCESS: {
      const newHitsArticles = { ...state.hitsArticles };
      newHitsArticles.data = action.payload.results?.results?.[0].hits || [];
      const paginatedArticlesHits = { ...state.paginatedArticlesHits };
      if (action.payload.paginated) {
        paginatedArticlesHits[
          bubbleUtils.string.buildSortedQueryString({
            text: action.payload.text,
            ...action.payload.params,
          })
        ] = { hits: action.payload.results?.results?.[0].hits || [] };
      }
      return {
        ...state,
        hits: resetHits(),
        paginatedArticlesHits,
        ['hitsArticles' + (action.payload.persistent ? 'Persistent' : '')]: newHitsArticles,
        loading: false,
        errors: [],
      };
    }
    case SEARCH_ARTICLES_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_STORES: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_STORES_SUCCESS: {
      let newHitsStores = { ...state.hitsStores };
      newHitsStores.data = action.payload.results?.results?.[0].hits || [];
      return {
        ...state,
        hits: resetHits(),
        ['hitsStores' + (action.payload.persistent ? 'Persistent' : '')]: newHitsStores,
        loading: false,
        errors: [],
      };
    }
    case SEARCH_STORES_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_PUBLISHERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_PUBLISHERS_SUCCESS: {
      let newHitsPublishers = { ...state.hitsPublishers };
      newHitsPublishers.data = action.payload.results?.results?.[0].hits || [];
      return {
        ...state,
        hits: resetHits(),
        ['hitsPublishers' + (action.payload.persistent ? 'Persistent' : '')]: newHitsPublishers,
        loading: false,
        errors: [],
      };
    }
    case SEARCH_PUBLISHERS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_COLLECTIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_COLLECTIONS_SUCCESS: {
      let newHitsCollections = { ...state.hitsCollections };
      newHitsCollections.data = action.payload.hits || [];
      return {
        ...state,
        hits: resetHits(),
        ['hitsCollections' + (action.payload.persistent ? 'Persistent' : '')]: newHitsCollections,
        loading: false,
        errors: [],
      };
    }
    case SEARCH_COLLECTIONS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_TYPES: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_TYPES_SUCCESS: {
      let newHitsTypes = { ...state.hitsTypes };
      newHitsTypes.data = action.payload.hits || [];
      return {
        ...state,
        hits: resetHits(),
        ['hitsTypes' + (action.payload.persistent ? 'Persistent' : '')]: newHitsTypes,
        loading: false,
        errors: [],
      };
    }
    case SEARCH_TYPES_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_TAGS: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_TAGS_SUCCESS: {
      let newHitsTags = { ...state.hitsTags };
      newHitsTags.data = action.payload.results?.results?.[0].hits || [];
      return {
        ...state,
        hits: resetHits(),
        ['hitsTags' + (action.payload.persistent ? 'Persistent' : '')]: newHitsTags,
        loading: false,
        errors: [],
      };
    }
    case SEARCH_TAGS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_RELAYS: {
      return {
        ...state,
        loading: true,
        errors: initialState.errors,
      };
    }
    case SEARCH_RELAYS_SUCCESS: {
      return {
        ...state,
        hitsRelays: action.payload.hits,
        loading: false,
        errors: initialState.errors,
      };
    }
    case SEARCH_RELAYS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_PLACES: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_PLACES_SUCCESS: {
      return {
        ...state,
        places: action.payload.hits,
        loading: false,
      };
    }
    case SEARCH_PLACES_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case RESET_SEARCH: {
      return { ...initialState };
    }
    case SET_IS_ACTIVE:
      return {
        ...state,
        isActive: action.payload.state,
      };
    case RESET_RELAYS:
      return {
        ...state,
        relays: [],
      };

    default: {
      return state;
    }
  }
}

// resetSearch ====================================================

export function resetSearch() {
  return {
    type: RESET_SEARCH,
  };
}

export function setIsActive(state) {
  return {
    type: SET_IS_ACTIVE,
    payload: { state: state },
  };
}

export function resetRelays() {
  return {
    type: RESET_RELAYS,
  };
}

// searchMultiIndex ====================================================
// == search multi index (serie & authors)

export function searchMultiIndex(indexes, text, persistent = false) {
  return {
    type: SEARCH_MULTI_INDEX,
    payload: {
      indexes,
      text,
      persistent,
    },
  };
}
export function searchMultiIndexSuccess(hits, persistent) {
  return {
    type: SEARCH_MULTI_INDEX_SUCCESS,
    payload: {
      hits,
      persistent,
    },
  };
}
export function searchMultiIndexFail(error) {
  return {
    type: SEARCH_MULTI_INDEX_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// searchAuthors ====================================================

export function searchAuthors(text, params, persistent = false) {
  return {
    type: SEARCH_AUTHORS,
    payload: {
      text,
      params,
      persistent,
    },
  };
}
export function searchAuthorsSuccess(results, persistent) {
  return {
    type: SEARCH_AUTHORS_SUCCESS,
    payload: {
      results,
      persistent,
    },
  };
}
export function searchAuthorsFail(error) {
  return {
    type: SEARCH_AUTHORS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// searchStores ====================================================

export function searchStores(text, params, persistent = false) {
  return {
    type: SEARCH_STORES,
    payload: {
      text,
      params,
      persistent,
    },
  };
}
export function searchStoresSuccess(results, persistent) {
  return {
    type: SEARCH_STORES_SUCCESS,
    payload: {
      results,
      persistent,
    },
  };
}
export function searchStoresFail(error) {
  return {
    type: SEARCH_STORES_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// searchRelays ====================================================

export function searchRelays(zipcode, countryCode) {
  return {
    type: SEARCH_RELAYS,
    payload: { zipcode, countryCode },
  };
}
export function searchRelaysSuccess(hits) {
  return {
    type: SEARCH_RELAYS_SUCCESS,
    payload: {
      hits,
    },
  };
}
export function searchRelaysFail(error) {
  return {
    type: SEARCH_RELAYS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// searchSeries ====================================================

export function searchSeries(text, params, persistent = false) {
  return {
    type: SEARCH_SERIES,
    payload: {
      text,
      params,
      persistent,
    },
  };
}
export function searchSeriesSuccess(results, persistent) {
  return {
    type: SEARCH_SERIES_SUCCESS,
    payload: {
      results,
      persistent,
    },
  };
}
export function searchSeriesFail(error) {
  return {
    type: SEARCH_SERIES_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// searchAlbums ====================================================

export function searchAlbums(text, params, persistent = false) {
  return {
    type: SEARCH_ALBUMS,
    payload: {
      text,
      params,
      persistent,
    },
  };
}
export function searchAlbumsSuccess(results, persistent) {
  return {
    type: SEARCH_ALBUMS_SUCCESS,
    payload: {
      results,
      persistent,
    },
  };
}
export function searchAlbumsFail(error) {
  return {
    type: SEARCH_ALBUMS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// searchArticles ====================================================

export function searchArticles(text, params, persistent = false, paginated = false) {
  return {
    type: SEARCH_ARTICLES,
    payload: {
      text,
      params,
      persistent,
      paginated,
    },
  };
}
export function searchArticlesSuccess(results, persistent, paginated, text, params) {
  return {
    type: SEARCH_ARTICLES_SUCCESS,
    payload: {
      results,
      persistent,
      paginated,
      text,
      params,
    },
  };
}
export function searchArticlesFail(error) {
  return {
    type: SEARCH_ARTICLES_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// searchPublishers ====================================================

export function searchPublishers(text, params, persistent = false) {
  return {
    type: SEARCH_PUBLISHERS,
    payload: {
      text,
      params,
      persistent,
    },
  };
}
export function searchPublishersSuccess(results, persistent) {
  return {
    type: SEARCH_PUBLISHERS_SUCCESS,
    payload: {
      results,
      persistent,
    },
  };
}
export function searchPublishersFail(error) {
  return {
    type: SEARCH_PUBLISHERS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// searchCollections ====================================================

export function searchCollections(text, params, persistent = false) {
  return {
    type: SEARCH_COLLECTIONS,
    payload: {
      text,
      params,
      persistent,
    },
  };
}
export function searchCollectionsSuccess(hits, persistent) {
  return {
    type: SEARCH_COLLECTIONS_SUCCESS,
    payload: {
      hits,
      persistent,
    },
  };
}
export function searchCollectionsFail(error) {
  return {
    type: SEARCH_COLLECTIONS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// searchTypes ====================================================

export function searchTypes(text, params, persistent = false) {
  return {
    type: SEARCH_TYPES,
    payload: {
      text,
      params,
      persistent,
    },
  };
}
export function searchTypesSuccess(hits, persistent) {
  return {
    type: SEARCH_TYPES_SUCCESS,
    payload: {
      hits,
      persistent,
    },
  };
}
export function searchTypesFail(error) {
  return {
    type: SEARCH_TYPES_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// searchTags ====================================================

export function searchTags(text, params, persistent = false) {
  return {
    type: SEARCH_TAGS,
    payload: {
      text,
      params,
      persistent,
    },
  };
}
export function searchTagsSuccess(results, persistent) {
  return {
    type: SEARCH_TAGS_SUCCESS,
    payload: {
      results,
      persistent,
    },
  };
}
export function searchTagsFail(error) {
  return {
    type: SEARCH_TAGS_FAIL,
    payload: new Error(error),
    error: true,
  };
}

// searchPlaces ====================================================

export function searchPlaces(partialAddress, location) {
  return {
    type: SEARCH_PLACES,
    payload: {
      partialAddress,
      location,
    },
  };
}
export function searchPlacesSuccess(hits) {
  return {
    type: SEARCH_PLACES_SUCCESS,
    payload: { hits },
  };
}
export function searchPlacesFail(error) {
  return {
    type: SEARCH_PLACES_FAIL,
    payload: new Error(error),
    error: true,
  };
}

import { liteClient as algoliasearch } from 'algoliasearch/lite';
import config from 'bubble-config';

const AlgoliaServices = {};

const algoliaClient = algoliasearch(config.algolia.appID, config.algolia.apiKey);

const formatTextForSearch = (text) => {
  // format ISBN to 13 digits
  const cleanText = text ? text.replace(/-/gi, '') : '';
  // check if the text is a number without tiling spaces
  // isFinite is there to handle the special case where text is "Infinity"
  if (
    Number(cleanText) == cleanText &&
    Number.isFinite(cleanText) &&
    String(Number(cleanText)).length === cleanText.length
  ) {
    text = cleanText;
    // add 978 at the begining for ISBN staring without it
    if (
      text.length > 4 &&
      text.length <= 10 &&
      text.slice(0, 3) !== '978' &&
      text.slice(0, 3) !== '979'
    ) {
      text = '978' + text;
    }
  }
  return text;
};

AlgoliaServices.multiIndexSearch = (indexes, text) => {
  if (!indexes) {
    indexes = [
      { name: config.algolia.albumsIndex, params: { hitsPerPage: 10 } },
      { name: config.algolia.seriesIndex, params: { hitsPerPage: 10 } },
      { name: config.algolia.authorsIndex, params: { hitsPerPage: 10 } },
      { name: config.algolia.publishersIndex, params: { hitsPerPage: 10 } },
      { name: config.algolia.storesIndex, params: { hitsPerPage: 10, filters: 'isPublic=1' } },
      { name: config.algolia.articlesIndex, params: { hitsPerPage: 10 } },
    ];
  }
  const queries = indexes.map((index) => {
    return {
      indexName: index.name,
      query: formatTextForSearch(text),
      attributesToHighlight: [],
      ...index.params,
    };
  });

  return new Promise((resolve, reject) =>
    algoliaClient
      .search(queries)
      .then((content) => resolve(content))
      .catch((error) => reject(error)),
  );
};

AlgoliaServices.searchAuthors = (text, params) => {
  return algoliaClient.search({
    requests: [
      {
        indexName: config.algolia.authorsIndex,
        query: formatTextForSearch(text),
        attributesToHighlight: [],
        ...params,
      },
    ],
  });
};

AlgoliaServices.searchSeries = (text, params) => {
  return algoliaClient.search({
    requests: [
      {
        indexName: config.algolia.seriesIndex,
        query: formatTextForSearch(text),
        attributesToHighlight: [],
        ...params,
      },
    ],
  });
};

AlgoliaServices.searchStores = (text, params) => {
  return algoliaClient.search({
    requests: [
      {
        indexName: config.algolia.storesIndex,
        query: formatTextForSearch(text),
        filters: 'isPublic=1', // only shows public booksellers, basically remove "bdfugue.com" and "Les Yeux dans les Bulles"
        attributesToHighlight: [],
        ...params,
      },
    ],
  });
};

AlgoliaServices.searchAlbums = (text, params) => {
  return algoliaClient.search({
    requests: [
      {
        indexName: config.algolia.albumsIndex,
        query: formatTextForSearch(text),
        attributesToHighlight: [],
        ...params,
      },
    ],
  });
};

AlgoliaServices.searchArticles = (text, params) => {
  return algoliaClient.search({
    requests: [
      {
        indexName: config.algolia.articlesIndex,
        query: formatTextForSearch(text),
        attributesToHighlight: [],
        ...params,
      },
    ],
  });
};

AlgoliaServices.searchPublishers = (text, params) => {
  return algoliaClient.search({
    requests: [
      {
        indexName: config.algolia.publishersIndex,
        query: formatTextForSearch(text),
        attributesToHighlight: [],
        ...params,
      },
    ],
  });
};

AlgoliaServices.searchTags = (text, params) => {
  return algoliaClient.search({
    requests: [
      {
        indexName: config.algolia.tagsIndex,
        query: formatTextForSearch(text),
        attributesToHighlight: [],
        ...params,
      },
    ],
  });
};

export default AlgoliaServices;

import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import AlgoliaServices from '../services/algolia';
import { searchCollectionsApi, searchRelaysApi, searchTypesApi } from '../services/api';

import {
  SEARCH_ALBUMS,
  SEARCH_ARTICLES,
  SEARCH_AUTHORS,
  SEARCH_COLLECTIONS,
  SEARCH_MULTI_INDEX,
  SEARCH_PUBLISHERS,
  SEARCH_RELAYS,
  SEARCH_SERIES,
  SEARCH_STORES,
  SEARCH_TAGS,
  SEARCH_TYPES,
} from '../reducers/search';
import {
  searchAlbumsFail,
  searchAlbumsSuccess,
  searchArticlesFail,
  searchArticlesSuccess,
  searchAuthorsFail,
  searchAuthorsSuccess,
  searchCollectionsFail,
  searchCollectionsSuccess,
  searchMultiIndexFail,
  searchMultiIndexSuccess,
  searchPublishersFail,
  searchPublishersSuccess,
  searchRelaysFail,
  searchRelaysSuccess,
  searchSeriesFail,
  searchSeriesSuccess,
  searchStoresFail,
  searchStoresSuccess,
  searchTagsFail,
  searchTagsSuccess,
  searchTypesFail,
  searchTypesSuccess,
} from '../reducers/search';
import { getClientToken } from '../selectors';

import bubbleUtils from 'bubble-utils';

function* searchMultiIndexFlow(action) {
  try {
    const response = yield call(
      AlgoliaServices.multiIndexSearch,
      action.payload.indexes,
      action.payload.text,
    );
    yield put(searchMultiIndexSuccess(response, action.payload.persistent));
  } catch (error) {
    console.log('saga middleware error (searchMultiIndexFlow)', error);
    yield put(searchMultiIndexFail(error));
  }
}

function* searchAuthorsFlow(action) {
  try {
    const response = yield call(AlgoliaServices.searchAuthors, action.payload.text);
    yield put(searchAuthorsSuccess(response, action.payload.persistent));
  } catch (error) {
    console.log('saga middleware error (searchAuthorsFlow)', error);
    yield put(searchAuthorsFail(error));
  }
}

function* searchStoresFlow(action) {
  try {
    if (!action.payload.text) {
      // if no text is entered (or text is erased), bypass algolia so it doesn't return the first
      // stores in the list
      return yield put(searchStoresSuccess([], action.payload.persistent));
    }
    const response = yield call(AlgoliaServices.searchStores, action.payload.text);
    yield put(searchStoresSuccess(response, action.payload.persistent));
  } catch (error) {
    console.log('saga middleware error (searchStoresFlow)', error);
    yield put(searchStoresFail(error));
  }
}

function* searchSeriesFlow(action) {
  try {
    const response = yield call(
      AlgoliaServices.searchSeries,
      action.payload.text,
      action.payload.params,
    );
    yield put(searchSeriesSuccess(response, action.payload.persistent));
  } catch (error) {
    console.log('saga middleware error (searchSeriesFlow)', error);
    yield put(searchSeriesFail(error));
  }
}

function* searchAlbumsFlow(action) {
  try {
    const response = yield call(
      AlgoliaServices.searchAlbums,
      action.payload.text,
      action.payload.params,
    );
    yield put(searchAlbumsSuccess(response, action.payload.persistent));
  } catch (error) {
    console.log('saga middleware error (searchAlbumsFlow)', error);
    yield put(searchAlbumsFail(error));
  }
}

function* searchArticlesFlow(action) {
  try {
    const response = yield call(
      AlgoliaServices.searchArticles,
      action.payload.text,
      action.payload.params,
    );
    yield put(
      searchArticlesSuccess(
        response,
        action.payload.persistent,
        action.payload.paginated,
        action.payload.text,
        action.payload.params,
      ),
    );
  } catch (error) {
    console.log('saga middleware error (searchArticlesFlow)', error);
    yield put(searchArticlesFail(error));
  }
}

function* searchPublishersFlow(action) {
  try {
    const response = yield call(
      AlgoliaServices.searchPublishers,
      action.payload.text,
      action.payload.params,
    );
    yield put(searchPublishersSuccess(response, action.payload.persistent));
  } catch (error) {
    console.log('saga middleware error (searchPublishersFlow)', error);
    yield put(searchPublishersFail(error));
  }
}

function* searchCollectionsFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const queryString = bubbleUtils.string.buildQueryString({ text: action.payload.text });
    const response = yield call(searchCollectionsApi, clientToken, queryString);
    yield put(searchCollectionsSuccess(response, action.payload.persistent));
  } catch (error) {
    console.log('saga middleware error (searchCollectionsFlow)', error);
    yield put(searchCollectionsFail(error));
  }
}

function* searchTypesFlow(action) {
  try {
    const clientToken = yield select(getClientToken);
    const queryString = bubbleUtils.string.buildQueryString({ text: action.payload.text });
    const response = yield call(searchTypesApi, clientToken, queryString);
    yield put(searchTypesSuccess(response, action.payload.persistent));
  } catch (error) {
    console.log('saga middleware error (searchTypesFlow)', error);
    yield put(searchTypesFail(error));
  }
}

function* searchTagsFlow(action) {
  try {
    const response = yield call(
      AlgoliaServices.searchTags,
      action.payload.text,
      action.payload.params,
    );
    yield put(searchTagsSuccess(response, action.payload.persistent));
  } catch (error) {
    console.log('saga middleware error (searchTagsFlow)', error);
    yield put(searchTagsFail(error));
  }
}

function* searchRelaysFlow(action, params) {
  try {
    const client = yield select(getClientToken);
    const params = {
      zipcode: (action.payload.zipcode || '').toLowerCase() || null,
      'country-code': (action.payload.countryCode || '').toLowerCase() || null,
    };
    const response = yield call(searchRelaysApi, client, params);
    yield put(searchRelaysSuccess(response));
  } catch (error) {
    console.log('saga middleware error (searchRelaysFlow)', error);
    yield put(searchRelaysFail(error));
  }
}

export default function* searchWatcher() {
  yield all([
    takeLatest(SEARCH_MULTI_INDEX, searchMultiIndexFlow),
    takeLatest(SEARCH_AUTHORS, searchAuthorsFlow),
    takeLatest(SEARCH_STORES, searchStoresFlow),
    takeLatest(SEARCH_RELAYS, searchRelaysFlow),
    takeLatest(SEARCH_SERIES, searchSeriesFlow),
    takeLatest(SEARCH_ALBUMS, searchAlbumsFlow),
    takeLatest(SEARCH_ARTICLES, searchArticlesFlow),
    takeLatest(SEARCH_PUBLISHERS, searchPublishersFlow),
    takeLatest(SEARCH_COLLECTIONS, searchCollectionsFlow),
    takeLatest(SEARCH_TYPES, searchTypesFlow),
    takeLatest(SEARCH_TAGS, searchTagsFlow),
  ]);
}
